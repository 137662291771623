<template>
  <div class="p-grid">
    <Card class="style p-col" style="width: 100%">
      <template #title>
        <div class="p-d-flex p-jc-between">
          <div>
            <h3 class="p-m-0">
              <strong>{{ pageTitle }}</strong>
            </h3>
            <MultiSelectableBreadcrumb ref="breadcrumbData" :singleSelection="false"></MultiSelectableBreadcrumb>
          </div>
          <div>
            <Button
                v-tooltip.left="_t('primefaces.datatable.SORT_LABEL')"
                class="p-button-rounded p-button-success p-button-text"
                icon="pi pi-sort"
                style="font-size: 16px;"
                @click="toggleSort"
            />
            <OverlayPanel ref="srt">
              <div class="p-field">
                <label for="sortKey"> {{ _t('primefaces.datatable.SORT_LABEL') }} </label>
                <br>
                <Dropdown
                    id="sortKey"
                    v-model="sortKey"
                    :disabled="orderByPriority"
                    :options="alarmTableFilterKeys"
                    optionLabel="label"
                    optionValue="value" style="margin: 0px;"
                >
                </Dropdown>
              </div>
              <div class="p-field-checkbox">
                <Checkbox id="orderByPriority" v-model="orderByPriority" :binary="true"
                          :disabled="sortKey !== ''"></Checkbox>
                <label for="orderByPriority">{{ _t('filter_sorted_prio') }}</label>
              </div>

            </OverlayPanel>
            <ToggleButton
                v-model="groupFilter"
                v-tooltip.bottom="_t('label_Display_filters')"
                class="p-button-rounded p-button-text p-button-plain"
                offIcon="pi pi-filter"
                onIcon="pi pi-filter"
                style="font-size: 16px;"
            />
            <Button
                class="p-button-rounded p-button-text p-button-success mb-2"
                icon="pi pi-file-pdf"
                style="font-size: 16px;"
                @click="togglePDFDownload"
            />
            <OverlayPanel ref="pdfDownload">
              <div v-for="downloadItem in downloadPdfItems"
                   v-bind:key="downloadItem.label">
                <Button
                    :icon="downloadItem.icon"
                    :label="downloadItem.label"
                    class="p-button-rounded p-button-success p-button-text"
                    style="font-size: 16px;" @click="getFile(downloadItem.type)"
                />
              </div>
            </OverlayPanel>
            <Button
                class="p-button-rounded p-button-success p-button-text"
                icon="pi pi-file-excel"
                style="font-size: 16px;"
                @click="toggleExcelDownload"
            />
            <OverlayPanel ref="xlsDownload">
              <div v-for="downloadItem in downloadExcelItems"
                   v-bind:key="downloadItem.label">
                <Button
                    :icon="downloadItem.icon"
                    :label="downloadItem.label"
                    class="p-button-rounded p-button-success p-button-text"
                    style="font-size: 16px;" @click="getFile(downloadItem.type)"
                />
              </div>
            </OverlayPanel>
          </div>
        </div>
      </template>
      <template #content>
        <TabView
            v-model:activeIndex="activeTab"
            @tab-click="switchTab()"
        >
          <TabPanel :header="_t('label_alarmtable')">
            <Alarms
                ref="reportAll"
                :activeTab="0"
                :alarmTableValues="alarmTableValues"
                :group-filter="groupFilter"
                :org-filter="orgFilter"
                filters-prefix="all"
            />
          </TabPanel>
          <TabPanel :header="_t('menu.my_alarms')">
            <Alarms
                ref="myAlarms"
                :activeTab="1"
                :alarmTableValues="alarmTableValues"
                :group-filter="groupFilter"
                :org-filter="orgFilter"
                :sort-key="sortKey"
                filters-prefix="my"
            />
          </TabPanel>
          <!--          <TabPanel-->
          <!--              :disabled="true"-->
          <!--              :header="_t('menu.manual_alarms')"-->
          <!--              filters-prefix="manual"-->
          <!--          />-->
          <TabPanel :header="_t('label_history')">
            <Alarms
                ref="reportHistory"
                :activeTab="2"
                :alarmTableValues="alarmTableValues"
                :group-filter="groupFilter"
                :org-filter="orgFilter"
                filters-prefix="history"
            />
          </TabPanel>
          <TabPanel
              :disabled="true"
              :header="_t('menu.checkin_checkout')"
              filters-prefix="checkin"
          />
          <TabPanel
              :disabled="true"
              :header="_t('ALARM_MONITORING')"
              filters-prefix="monitor"
          />
<!--          <TabPanel-->
<!--              :disabled="true"-->
<!--              :header="_t('label_APP_Lone_worker')"-->
<!--              filters-prefix="monitor"-->
<!--          >-->
<!--            <LoneworkerHistory/>-->
<!--          </TabPanel>-->
<!--          <TabPanel-->
<!--              :disabled="true"-->
<!--              :header="_t('label_APP_Lone_worker_active')"-->
<!--              filters-prefix="monitor"-->
<!--          >-->
<!--            <LoneworkerActive/>-->
<!--          </TabPanel>-->
<!--          <TabPanel-->
<!--              :disabled="true"-->
<!--              :header="_t('Selection')"-->
<!--              filters-prefix="monitor"-->
<!--          >-->
<!--            <Selection/>-->
<!--          </TabPanel>-->
        </TabView>
      </template>
    </Card>
  </div>
</template>

<script>

import MultiSelectableBreadcrumb from '@/components/ixarma/MultiSelectableBreadcrumb';
import {associateColor} from '@/service/helper/styleHelper';
import Alarms from '@/views/alarm_table/alarms/index'
import {DurationEnum} from '@/enums/duration.enum';
import {getFileLink} from "@/api/alarmTable";
// import LoneworkerHistory from "@/components/LoneworkerHistory.vue";
// import LoneworkerActive from "@/components/LoneworkerActive.vue";
// import Selection from '@/components/Selection.vue'
import {
  alarmJobStatuses,
  alarmTableFilterKeys,
  downloadExcelItems,
  downloadPdfItems,
  initialColsData
} from "@/constants/constants";
import {getOrganizationColumns, getUserColumns} from "@/api/uiColumns";

export default {
  name: 'index',
  components: {
    'MultiSelectableBreadcrumb': MultiSelectableBreadcrumb,
    'Alarms': Alarms,
    // 'LoneworkerHistory': LoneworkerHistory,
    // 'LoneworkerActive': LoneworkerActive,
    // 'Selection': Selection,
  },
  data() {
    return {
      loading: true,
      currentPage: 0,
      totalRecords: 0,
      orderByPriority: false,
      hoursDuration: 23,
      alarms: [{
        alarmPointName: '',
        jobStatusColor: '',
      }],
      alarmTableRows: parseInt(localStorage.getItem('alarmTableRows') ?? 10),
      pageSize: [5, 10, 20, 50],
      alarmJobStatuses: alarmJobStatuses,
      alarmTableFilterKeys: alarmTableFilterKeys,
      durationFilters: {
        start: null,
        end: null,
        option: DurationEnum.RANGE,
      },
      sortKey: '',
      alarmFilterKey: '',
      alarmFilterValue: '',
      alarmFilters: {
        alarmName: null,
        alarmPointId: "",
        statuses: [],
        systemId: null,
        triggeredBy: null,
      },
      groupFilter: false,
      selectedAlarmTableItem: null,
      selectedJobId: null,
      allAlarmsIntervalId: null,
      orgStorageData: [],
      orgFilter: [],
      orgFilterIntervalId: null,
      activeTab: 0,
      participants: [],
      downloadExcelItems: downloadExcelItems,
      downloadPdfItems: downloadPdfItems,
      alarmTableValues: [],
    }
  },
  beforeUnmount() {
    clearInterval(parseInt(this.allAlarmsIntervalId))
  },
  computed: {
    pageTitle() {
      switch (this.activeTab) {
        case 1:
          return this._t('menu.my_alarms');
        case 2:
          return this._t('menu_history');
        default:
          return this._t('menu.alarm_table');
      }
    }
  },
  watch: {
    activeTab(tab) {
      let activeTabValue;

      if (tab === 0) {
        activeTabValue = localStorage.getItem(`af.all.sortKey`)
      }
      if (tab === 1) {
        activeTabValue = localStorage.getItem(`af.my.sortKey`)
      }
      if (tab === 2) {
        activeTabValue = localStorage.getItem(`af.history.sortKey`)
      }

      if (tab === 4 || tab === 5 || tab === 6) {
        activeTabValue = null
      }

      this.sortKey = JSON.parse(activeTabValue) || ''
    },
    sortKey(value) {
      switch (this.activeTab) {
        case 0:
          this.$refs.reportAll.sortKey = value
          break;
        case 1:
          this.$refs.myAlarms.sortKey = value
          break;
        case 2:
          this.$refs.reportHistory.sortKey = value
          break;
        default:
          this.$refs.reportAll.sortKey = value
          break;
      }
    },
    orderByPriority(value) {
      switch (this.activeTab) {
        case 0:
          this.$refs.reportAll.orderByPriority = value
          break;
        case 1:
          this.$refs.myAlarms.orderByPriority = value
          break;
        case 2:
          this.$refs.reportHistory.orderByPriority = value
          break;
        default:
          this.$refs.reportAll.orderByPriority = value
          break;
      }
    },
    '$route.query.tab'() {
      const tab = this.$route.query.tab ?? '0';
      this.activeTab = parseInt(tab);
      this.initTab();
    },
    hoursDuration(value) {
      if (value > 0) {
        this.initTab();
      } else {
        this.hoursDuration = 23
      }
    },
    groupFilter(value) {
      this.groupFilter = value
      this.saveFilterState()
    },
    orgFilter(value) {
      this.orgFilter = value
      this.saveFilterState()
    }
  },
  mounted() {
    const tab = this.$route.query.tab ?? '0';
    this.activeTab = parseInt(tab);
    this.initTab();
    this.checkOrgFilter()
    this.loadFilterState()

    getUserColumns().then((userRes) => {
      if (userRes.data) {
        for (const [key, value] of Object.entries(userRes.data)) {
          if (key === 'alarmTableItems') this.alarmTableValues = value
        }
      } else {
        getOrganizationColumns().then((res) => {
          if (!res.data) {
            res.data = initialColsData
          }
          for (const [key, value] of Object.entries(res.data)) {
            if (key === 'alarmTableItems') this.alarmTableValues = value
          }
        }).catch((err) => console.log('error', err))
      }
    }).catch((err) => console.log('error', err))
  },
  methods: {
    toggleSearch(event) {
      this.$refs.op.toggle(event);
    },
    toggleSort(event) {
      this.$refs.srt.toggle(event);
    },
    toggleExcelDownload(event) {
      this.$refs.xlsDownload.toggle(event);
    },
    togglePDFDownload(event) {
      this.$refs.pdfDownload.toggle(event);
    },
    loadFilterState() {
      let filters = localStorage.getItem('af.global.groupFilter')
      let sortKeyFilter = localStorage.getItem('af.all.sortKey')

      if (sortKeyFilter) this.sortKey = JSON.parse(sortKeyFilter)

      if (filters) {
        this.groupFilter = JSON.parse(filters)
      }

      filters = localStorage.getItem('af.global.orgFilter')
      if (filters) {
        this.orgFilter = JSON.parse(filters)
      }
    },
    saveFilterState() {
      if (this.groupFilter) {
        localStorage.setItem('af.global.groupFilter', JSON.stringify(this.groupFilter))
      }

      if (this.orgFilter) {
        localStorage.setItem('af.global.orgFilter', JSON.stringify(this.orgFilter))
      }
    },
    selectTablePage(item) {
      this.currentPage = item.page
      this.getAllAlarms()
    },
    onFilter() {
      this.loading = true
      this.getAllAlarms()
    },
    clearFilter() {
      this.alarmFilterValue = ''
    },
    checkOrgFilter() {
      this.checkOrgFilterIntervalId = setInterval(() => {
        if (this.orgStorageData !== localStorage.getItem('multiOrgIds')) {
          this.orgStorageData = localStorage.getItem('multiOrgIds')
          this.orgFilter = JSON.parse(localStorage.getItem('multiOrgIds'))
        }
      }, 100);
    },
    getFile(type) {
      let request
      switch (this.activeTab) {
        case 0:
          type = '/alarmtable/' + type
          request = this.$refs.reportAll;
          break;
        case 1:
          type = '/history/' + type
          request = this.$refs.myAlarms;
          break;
        case 2:
          type = '/history/' + type
          request = this.$refs.reportHistory;
          break;
        default:
          type = '/alarmtable/' + type
          request = this.$refs.reportAll;
          break;
      }
      getFileLink(type,
          request.currentPage,
          request.alarmFilterKey.value,
          request.alarmFilterValue,
          request.alarmTableRows,
          request.alarmFilters,
          request.orgFilter,
          request.durationFilters,
          request.orderByPriority,
          request.participantsFilter,
      ).then((response) => {
        const disposition = response.headers['content-disposition']
        if (disposition && disposition.indexOf('attachment') !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            let filename = matches[1].replace(/['"]/g, '');
            this.downloadFile(response, filename)
          }
        }
      })
    },
    downloadFile(response, filename) {
      var newBlob = new Blob([response.data])
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download = filename;
      link.click();
      setTimeout(function () {
        window.URL.revokeObjectURL(data), 100
      })
    },
    associateColor,
    switchTab() {
      this.$refs.reportAll.clearComponent()
      this.$refs.myAlarms.clearComponent()
      this.$refs.reportHistory.clearComponent()
      if (this.$route.query.tab === this.activeTab) {
        this.initTab()
      } else {
        this.$router.push(`/alarm-table?tab=${this.activeTab}`)
      }
    },
    initTab() {
      this.$route.query.tab = this.activeTab
      switch (this.activeTab) {
        case 0:
          if (!this.$route.query.alarmId) {
            this.$refs.reportAll.selectedJobId = null;
          }
          this.$refs.reportAll.initTab(this.activeTab, null, this.hoursDuration);
          break;
        case 1:
          if (!this.$route.query.alarmId) {
            this.$refs.myAlarms.selectedJobId = null;
          }
          this.$refs.myAlarms.initTab(this.activeTab, null, this.hoursDuration);
          break;
        case 2:
          if (!this.$route.query.alarmId) {
            this.$refs.reportHistory.selectedJobId = null;
          }
          this.$refs.reportHistory.initTab(this.activeTab, null, this.hoursDuration);
          break;
        default:
          this.$refs.reportAll.initTab(0);
          break;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.ui-sticky {
  top: 70px !important;
  position: fixed !important;
}

@media (max-width: 960px) {
  .ui-sticky {
    top: 110px !important;
  }
}

::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

.p-datatable .p-column-filter {
  //display: none;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

::v-deep(.p-datatable) {
  .p-datatable-header {
    padding: 10px;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 10px;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
    padding: 10px;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
    padding: 2px;
    font-weight: 1500;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: .3px;

  &.status-qualified {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-unqualified {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-negotiation {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.status-new {
    background: #B3E5FC;
    color: #23547B;
  }

  &.status-renewal {
    background: #ECCFFF;
    color: #694382;
  }

  &.status-proposal {
    background: #FFD8B2;
    color: #805B36;
  }
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: .5rem;
  }
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
  padding: 1rem;
}

.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: .3px;

  &.status-instock {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
  }
}

.order-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  letter-spacing: .3px;

  &.order-delivered {
    background: #C8E6C9;
    color: #256029;
  }

  &.order-cancelled {
    background: #FFCDD2;
    color: #C63737;
  }

  &.order-pending {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.order-returned {
    background: #ECCFFF;
    color: #694382;
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;

      }

      .p-datatable-tbody > tr {
        border-bottom: 1px solid white;

        > td {
          text-align: left !important;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {

            min-width: 30%;
            display: inline-block;
            margin: -.4rem 1rem -.4rem -.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: .5rem;
            display: inline-block;
            width: 60%;
          }

          .p-rating {
            display: inline-block;
          }
        }
      }

      .p-datatable-tbody > tr.p-rowgroup-footer {
        display: flex;
      }
    }
  }
}

.true-icon {
  color: #256029;
}

.false-icon {
  color: #C63737;
}

::v-deep(.p-treetable) {
  .p-treetable-tbody > tr > td {
    padding: 5px;
  }

  .p-treetable-thead > tr > th {
    padding: 5px;
  }

}


.customer {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.customer td, .customers th {
  border: none;
  padding: 5px;
}

.customer tr:nth-child(even) {
  //background-color: #f2f2f2;
}

.truncate {
  min-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disable-th th {
  display: none;
}

p-tabview-nav-link {
  color: #82B541;
  border-color: #82B541;
}

.p-highlight {
  background-color: #82B541 !important;
}

.custom-single-view-filter {
  width: 30% !important;
  margin: 5px !important;
}


</style>
